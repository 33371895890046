import {
  ref, watch, computed,
} from '@vue/composition-api'

export default function useList(theTemplateData) {
  const refListTable = ref(null)

  // Table Handlers
  const checkpointsTableColumns = [
    { key: 'checkpoint_order', label: 'Order', sortable: true },
    { key: 'name', label: 'Checkpoint Name', sortable: true },
    { key: 'geo_required', label: 'GEO Required', sortable: true },
    { key: 'point_description', label: 'Description', sortable: false },
  ]
  const checkpointsperPage = ref(10)
  const totalCheckpoints = ref(0)
  const checkpointsCurrentPage = ref(1)
  const checkpointsperPageOptions = [10, 25, 50, 100]
  const sortBy = ref('checkpoint_order')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const theList = ref(null)
  const theListComplete = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: checkpointsperPage.value * (checkpointsCurrentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: checkpointsperPage.value * (checkpointsCurrentPage.value - 1) + localItemsCount,
      of: totalCheckpoints.value,
    }
  })

  const refetchData = () => {
    console.log('refetchData')
    refListTable.value.refresh()
  }

  watch([checkpointsCurrentPage, checkpointsperPage], () => {
    console.log('Watch')
    refetchData()
  })

  const fetchListItems = (ctx, callback) => {
    console.log('fetchListItems')
    console.log(ctx)
    console.log('PASSED')
    const qvars = {
      forceRefresh: true,
      checkpointsperPage: checkpointsperPage.value,
      page: checkpointsCurrentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    console.log(qvars)
    // TODO: Need to reorder checkpoints accordingly
    if (theTemplateData && theTemplateData.checkpoints) {
      callback(theTemplateData.checkpoints)
    } else {
      callback({})
    }
  }

  return {
    fetchListItems,
    checkpointsTableColumns,
    checkpointsperPage,
    checkpointsCurrentPage,
    totalCheckpoints,
    theList,
    theListComplete,
    dataMeta,
    checkpointsperPageOptions,
    sortBy,
    isBusy,
    isSortDirDesc,
    refListTable,
    refetchData,
  }
}
